:root {
  /* --first: #3c3c46; */
  --first: #333333;
  --secound: #ffffff;
  --frame: 10px;
  --alpha: #FFC83D;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Muli', sans-serif;
  -webkit-tap-highlight-color: transparent;
  cursor: url("mouse.png"), auto;
}
html {
  scroll-behavior: smooth;
}

svg {
  cursor: pointer;
}
a {
  cursor: pointer;
}
img {
  width: 100%;
  min-height: 50px;
}
body {
  width: 100%;
  height: 100%;
  border-left: var(--frame) solid var(--first);
  border-right: var(--frame) solid var(--first);
  background-color: var(--secound);
}
.border_fixer_top {
  width: 100%;
  height: var(--frame);
  background-color: var(--first);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.border_fixer_bottom {
  width: 100%;
  height: var(--frame);
  background-color: var(--first);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* HEADER */
header {
  margin-top: var(--frame);
  width: 100%;
}
header nav {
  width: 100%;
  display: flex;
  padding: 15px 0;
}
header nav ul {
  list-style: none;
  display: flex;
  text-align: center;
  margin: 0 auto;
}
header nav ul li {
  margin: 5px 20px;
}
header nav ul li a {
  text-decoration: none;
  color: var(--first);
  
  position: relative;
  padding: 14px 0;
  font-family: 'Muli', sans-serif;
  
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.040em;
}
header nav ul li a svg {
  fill: none;
  stroke: var(--first);
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 338;
  stroke-dashoffset: 338;
  stroke-linecap: round;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(75% + 50px);
  /* width: calc(100% + 60px); */
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.active svg {
  stroke: var(--alpha);
}

.active  svg,
header nav ul li a:hover svg {
  stroke-dashoffset: 0;
  opacity: 1;
  transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* HOME */
.home {
  width: 100%;
  padding: 29vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.home h3 {
  font-size: 2rem;
}
.home h3 span {
  color: var(--alpha);
  font-weight: 100;
}
.home h1 {
  color: var(--first);
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: -0.5px;
  width: 70%;
  margin: 10px 0;
}
.link_set ul {
  /* margin: 15px auto; */
  list-style: none;
  display: flex;
}
.link_set ul li {
  margin: 0 5px;
  cursor: pointer;
}
.link_set ul li a {
  text-decoration: none;

}
.link_set ul li a svg {
  fill: var(--first);
  width: 30px;
}

.image_box {
  width: 100px;
}
.image_box img {
  border-radius: 50%;
  cursor: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

}



@media screen and (max-width: 400px) {
  header nav ul li a {
      font-size: 0.9rem;
  }
  header nav ul li a svg {
      width: calc(70% + 45px);
  }
}







.about {
  width: 100%;
  padding: 15vh 0;
}


.about__heading {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
}

.about__heading__title {
  flex-basis: 50%;
  text-align: center;

  font-size: 4rem;
  font-weight: 900;
  color: var(--first);
  line-height: 3rem;
  /* -webkit-text-fill-color: var(--redmain);
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: var(--darkred); */
}

.about__heading__empty {
  flex-basis: 50%;
}

.about__info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.about__info_empty {
  flex-basis: 150%;
}

/*60*/
.about__info_empty_two {
  flex-basis: 60%;
}

.about__info__desc {
  /* flex-basis: 40%; */
  font-size: 1.2rem;
  color: var(--first);
  /* font-weight: 600; */
  font-family: 'Anonymous Pro', monospace;

}


.about_tech__heading {
  margin: 10px 0;
  /* font-family: 'Kanit', sans-serif; */
  text-transform: capitalize;
  letter-spacing: -0.5px;
  font-weight: 900;
  color: var(--first);
  /* -webkit-text-fill-color: var(--first);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--first); */
}
.about__tech_tags {
  display: flex;
  max-width: 700px;
  flex-wrap: wrap;

}

.cutombtntool {
  padding: 5px 6px;
  margin: 3px;
  background-color: rgba(242, 242, 242, 1);
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  color: var(--first);
  cursor: default !important;
}

@media only screen and (max-width: 770px) {
  .about__tech_tags {
      width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .about__heading__title {
      font-size: 3rem;
      text-align: end !important;
  }

  .about__info__desc {
      font-size: 1rem;
  }
  .cutombtntool {
      padding: 3px 4px;
      margin: 5px 3px;
      font-size: 0.9rem;
  }
}

@media only screen and (max-width: 320px) {

  .cutombtntool {
      padding: 3px 3px;
      margin: 4px 2px;
      /* font-size: 0.7rem; */
  }

}

/* projects */

.list-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  

  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  
}
.list-image:hover{
  -webkit-filter: grayscale(100%);
  filter: grayscale(0%);

}
.projects {
  width: 100%;
  
}
.projects-list {
  width: 80%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
}
.project {
  margin: 10px;
  padding: 45px 0;
}
.projects__heading {
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
}

.projects__heading__title {
  flex-basis: 50%;
  text-align: center;
  margin-left: 10px;
  font-size: 4rem;
  font-weight: 900;
  color: var(--first);
  line-height: 3rem;
}

.projects__heading__empty {
  flex-basis: 50%;
}

.project:nth-child(even) {
  margin: 10rem 0;
}

.project a{
  text-decoration: none;
  color: var(--first);
}
.project a h3 {
  display: inline;
  font-size: 1.5rem;
  border-bottom: 3px solid var(--alpha);
  cursor: pointer;
}

.project p {
  margin: 10px 0;
}
.each-heading {
  padding: 0 10px;
  
}
@media screen and (max-width: 600px) { 
  .project:nth-child(even) {
      margin: 1rem 0;
  }
  .project:nth-child(odd) {
      margin: 1rem 0;
  }
  .projects-list {
      grid-template-columns:  1fr;
  }
}